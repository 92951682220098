@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  font-size: 2vw;
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 150%;
  }
}

@media print {
  html {
    font-size: 100%;
  }
}

@page {
  size: A4;
  margin: 0;
}

body {
  margin: 0;
  -webkit-print-color-adjust: exact !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
